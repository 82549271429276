<template>
    <div class="rounded-2xl bg-white p-6 shadow dark:bg-black dark:shadow-dark">
        <div class="flex flex-wrap items-center justify-between gap-2">
            <h3 class="text-2xl font-semibold dark:text-light">近期主导项目</h3>
            <router-link to="/about"
                class="inline-flex items-center justify-center gap-2 border-b text-center text-base text-primary transition hover:border-b-primary dark:border-b-muted dark:hover:border-b-primary">
                <span>查看更多</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="none" stroke="currentColor"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" class="h-5 w-5">
                    <path d="M4.167 10h11.666m-4.999 5 5-5m-5-5 5 5"></path>
                </svg>
            </router-link>
        </div>

        <div class="mt-6 space-y-6">
            <div class="group relative overflow-hidden rounded-lg bg-light p-4 pb-0 dark:bg-dark-2 md:p-6 md:pb-0">
                <div class="relative aspect-6/4 overflow-hidden rounded-t-lg">
                    <img src="/img/pic/ailab.png" alt=""
                        class="h-full w-full rounded-t-lg object-cover object-top transition">

                        <router-link to="/about" data-gall="project-gallry-1"
                        class="project-gallery-link absolute left-1/2 top-1/2 grid h-10 w-10 -translate-x-1/2 -translate-y-1/2 place-content-center rounded-full bg-white text-primary shadow-lg transition lg:invisible lg:-translate-y-[40%] lg:opacity-0 lg:group-hover:visible lg:group-hover:-translate-y-1/2 lg:group-hover:opacity-100">
                        <svg t="1719242352208" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4700" width="20" height="20">
                            <path
                                d="M809.246403 771.926938 777.737782 803.458071 924.965961 950.667831 956.476629 919.157163Z"
                                fill="#1296db" p-id="4701"></path>
                            <path
                                d="M454.878536 70.285786C239.51556 70.285786 64.93236 244.847497 64.93236 460.231963c0 215.341486 174.583201 389.945153 389.945153 389.945153 215.362976 0 389.9472-174.603667 389.9472-389.945153C844.825736 244.847497 670.241512 70.285786 454.878536 70.285786zM454.878536 805.611108c-190.750415 0-345.381192-154.626683-345.381192-345.379145 0-190.751439 154.629753-345.380168 345.381192-345.380168 190.752462 0 345.382215 154.62873 345.382215 345.380168C800.259728 650.983401 645.630998 805.611108 454.878536 805.611108z"
                                fill="#1296db" p-id="4702"></path>
                        </svg>
                        </router-link>
                </div>

                <div class="absolute inset-x-0 bottom-0 flex flex-wrap gap-2 bg-gradient-to-t from-black/20 p-4">
                    <span class="rounded bg-white px-2 py-1 text-xs font-medium text-primary shadow">
                        LLM人工智能开发
                    </span>
                </div>
            </div>
            <div class="group relative overflow-hidden rounded-lg bg-light p-4 pb-0 dark:bg-dark-2 md:p-6 md:pb-0">
                <div class="relative aspect-6/4 overflow-hidden rounded-t-lg">
                    <img src="/img/pic/basetec1.jpg" alt=""
                        class="h-full w-full rounded-t-lg object-cover object-top transition">

                        <router-link to="/about" data-gall="project-gallry-2"
                        class="project-gallery-link absolute left-1/2 top-1/2 grid h-10 w-10 -translate-x-1/2 -translate-y-1/2 place-content-center rounded-full bg-white text-primary shadow-lg transition lg:invisible lg:-translate-y-[40%] lg:opacity-0 lg:group-hover:visible lg:group-hover:-translate-y-1/2 lg:group-hover:opacity-100">
                        <svg t="1719242352208" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4700" width="20" height="20">
                            <path
                                d="M809.246403 771.926938 777.737782 803.458071 924.965961 950.667831 956.476629 919.157163Z"
                                fill="#1296db" p-id="4701"></path>
                            <path
                                d="M454.878536 70.285786C239.51556 70.285786 64.93236 244.847497 64.93236 460.231963c0 215.341486 174.583201 389.945153 389.945153 389.945153 215.362976 0 389.9472-174.603667 389.9472-389.945153C844.825736 244.847497 670.241512 70.285786 454.878536 70.285786zM454.878536 805.611108c-190.750415 0-345.381192-154.626683-345.381192-345.379145 0-190.751439 154.629753-345.380168 345.381192-345.380168 190.752462 0 345.382215 154.62873 345.382215 345.380168C800.259728 650.983401 645.630998 805.611108 454.878536 805.611108z"
                                fill="#1296db" p-id="4702"></path>
                        </svg>
                        </router-link>
                </div>

                <div class="absolute inset-x-0 bottom-0 flex flex-wrap gap-2 bg-gradient-to-t from-black/20 p-4">
                    <span class="rounded bg-white px-2 py-1 text-xs font-medium text-primary shadow">
                        商业运维
                    </span>
                </div>
            </div>
            <div class="group relative overflow-hidden rounded-lg bg-light p-4 pb-0 dark:bg-dark-2 md:p-6 md:pb-0">
                <div class="relative aspect-6/4 overflow-hidden rounded-t-lg">
                    <img src="/img/pic/cc2.png" alt=""
                        class="h-full w-full rounded-t-lg object-cover object-top transition">

                        <router-link to="/about" data-gall="project-gallry-2"
                        class="project-gallery-link absolute left-1/2 top-1/2 grid h-10 w-10 -translate-x-1/2 -translate-y-1/2 place-content-center rounded-full bg-white text-primary shadow-lg transition lg:invisible lg:-translate-y-[40%] lg:opacity-0 lg:group-hover:visible lg:group-hover:-translate-y-1/2 lg:group-hover:opacity-100">
                        <svg t="1719242352208" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4700" width="20" height="20">
                            <path
                                d="M809.246403 771.926938 777.737782 803.458071 924.965961 950.667831 956.476629 919.157163Z"
                                fill="#1296db" p-id="4701"></path>
                            <path
                                d="M454.878536 70.285786C239.51556 70.285786 64.93236 244.847497 64.93236 460.231963c0 215.341486 174.583201 389.945153 389.945153 389.945153 215.362976 0 389.9472-174.603667 389.9472-389.945153C844.825736 244.847497 670.241512 70.285786 454.878536 70.285786zM454.878536 805.611108c-190.750415 0-345.381192-154.626683-345.381192-345.379145 0-190.751439 154.629753-345.380168 345.381192-345.380168 190.752462 0 345.382215 154.62873 345.382215 345.380168C800.259728 650.983401 645.630998 805.611108 454.878536 805.611108z"
                                fill="#1296db" p-id="4702"></path>
                        </svg>
                        </router-link>
                </div>

                <div class="absolute inset-x-0 bottom-0 flex flex-wrap gap-2 bg-gradient-to-t from-black/20 p-4">
                    <span class="rounded bg-white px-2 py-1 text-xs font-medium text-primary shadow">
                       客户活动策划
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>