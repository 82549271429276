import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// 外部css资源
//字体库
const head = document.getElementsByTagName('head')[0];
const link1 = document.createElement('link');
link1.rel = 'preconnect';
link1.href = 'https://fonts.googleapis.com';
head.appendChild(link1);
const link2 = document.createElement('link');
link2.rel = 'preconnect';
link2.href = 'https://fonts.gstatic.com';
link2.crossOrigin = '';
head.appendChild(link2);

//样式
import './assets/css/css2.css';
import './assets/css/swiper-bundle.min.css';
import './assets/css/venobox.min.css';
import './assets/css/style.css';

// 外部js资源
import './assets/js/preline.js';
import './assets/js/swiper-bundle.min.js';
import './assets/js/venobox.min.js';
import './assets/js/clipboard.min.js';
// import './assets/js/main.js';
createApp(App).use(store).use(router).mount('#app')
