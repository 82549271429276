<template>
    <div class="rounded-2xl bg-white p-6 shadow dark:bg-black dark:shadow-dark">
                <marquee behavior="scroll" direction="left" class="overflow-hidden text-nowrap rounded-lg bg-light p-3 text-lg font-medium text-muted dark:bg-dark-2">
                     已离职，可随时到岗 🚀 如有合适的工作，请与我取得联系 🎨 
                </marquee>

                <h2 class="mt-4 text-[40px] font-semibold leading-snug text-dark dark:text-light">
                   期待👋 <br>
						与您的沟通
                </h2>

                <a href="mailto:krinchao@gmail.com" class="mt-6 inline-flex items-center justify-center gap-2 border-b text-center text-base text-primary transition hover:border-b-primary dark:border-b-muted dark:hover:border-b-primary">
                    <span>联系我</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" class="h-5 w-5">
                        <path d="M17.5 11.667v-5h-5"></path>
                        <path d="m17.5 6.667-7.5 7.5-7.5-7.5"></path>
                    </svg>
                </a>
            </div>
</template>