import { createRouter, createWebHistory,nextTick } from 'vue-router'
import HomeView from '@/components/home/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/components/about/index.vue')
  },
  {
    path: '/project',
    name: 'project',
    redirect: "/project/1",//默认加载某个页面
    children: [
      {
        //二级导航路径不要加 /
        path: "1",
        component:  () => import("@/components/project/1.vue"),
      },
      {
        path: "2",
        component: () => import("@/components/project/2.vue")
      },
      {
        path: "3",
        component: () => import("@/components/project/3.vue")
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 路由切换时，始终滚动到顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };  // 每次切换到新页面时，滚动到顶部
    }
  },
})
router.afterEach(() => {
  // 滚动到页面顶部
  window.scrollTo(0, 0);
// 检查是否存在展开的二级菜单并关闭它
const menu = document.querySelector('#mobile-menu');
  if (menu) {
    menu.classList.remove('w-2 open');
    menu.classList.add('w-2 hidden');
  }

  // 使用 nextTick 确保在 DOM 更新后滚动
  nextTick(() => {
    window.scrollTo(0, 0);
  });
});

export default router
